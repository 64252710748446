/*jslint browser: true*/
/*global error,success*/
(function($)
{
	$.importer = {
		init: function()
		{	
			if(!$('#importer').length){return;}
			$.importer.importData('authentication');
		},
		importData: function($method) {
			var $ajaxurl = $('#importer').data('ajaxurl'),
				$security = $('#importer').data('security');
				
			$.post( $ajaxurl, { method: $method, _token: $security }, function(data) {
				$('#importer h4').text(data.message);
				$('#importer .progress-bar').css( { width: data.progress+'%' } ).attr( 'aria-valuenow', 100 ).html(data.progress_val);
				if (data.status === "error") {
					$('#importer').append('<a href="javascript:;" onclick="window.history.back()" class="btn">Retry</a>');
					$('#importer .progress-bar').css( { width: '100%' } ).attr( 'aria-valuenow', 100 ).text('100%');
				} else {
					if ( data.next_step ) {
						if(data.log) {
							$('#importer .note').before('<p>'+data.log+'</p>');
						}
						if ( data.next_step === 'complete' ) {
							$('#importer').append('<a href="javascript:;" onclick="window.history.back()" class="btn">Import Successfull <i class="fa fa-check"><i></a>');
						} else {
							$.importer.importData(data.next_step);
						}
					};
				}
				return true;
			});
		}
	};
	
	$(function()
	{
		$.importer.init();
	});
})(jQuery);
