(function($)
{    
	// Ok lets go...
    $.estimate = {
		send: function() {
			
			var $modalTemplate = $('<div>'+$('#estimate-send-jstemplate').html()+'</div>');
			
			$.modal.show({
				title: "Send Estimate",
				content: $modalTemplate.html(),
				formAction: location.href,
				actionText: "Send Email",
				actionTextSubmitting: "Sending Email <i class='fa fa-spinner fa-spin'></i>",
				openCallback: function() {
		            $.formInputs.init();
					$.formSelect2.init();
				}
			});
		},
		restore : function() {
			return $.dialog.confirm({
				title: "Restore Estimate",
				description: "Are your sure you want to restore this estimate?",
				actionText: "Trash",
				callback: function() {
					$('#restoreEstimateForm').removeClass('submitting').submit();
				}
			});
		},
		trash : function() {
			return $.dialog.confirm({
				title: "Trash Estimate",
				description: "Are your sure you want to trash this estimate?",
				actionText: "Trash",
				callback: function() {
					$('#trashEstimateForm').removeClass('submitting').submit();
				}
			});
		}
	};
})(jQuery);