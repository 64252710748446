(function($)
{    
	// Ok lets go...
    $.invoice_recurring = {
		init: function()
		{	
			$("#client").on('change',function(e) {
				$.invoice_recurring.get_contacts($(this).val());
			});
		},
		toggle_senddetails: function($show) {
			if($show) {
				$('.toggle-send-details').show();
			} else {
				$('.toggle-send-details').hide();
			}
		},
		toggle_custom_frequency: function($val) {
			if($val === 'Custom') {
				$('.custom_days').show();
			} else {
				$('.custom_days').hide();
			}
		},
		get_contacts: function($client_id) {
			$('#clients-loading').show();
			$('#company-contacts').hide();
			// grab the forms security token...
			var token = $('input[name="_token"]').first().val();
			
			$( "#company-contacts" ).load(params.baseURL+"/recurring-invoices/contacts/", { "client_id":$client_id,"_token":token }, function(response, status, xhr) {
				if ( status == "error" ) {
					var msg = "Sorry but there was an error: ";
					$.dialog.alert({title:'Error', description: msg + xhr.status + " " + xhr.statusText});
				} else {
					$.formInputs.init();
					$.formSelect2.init();
					$.invoice_recurring.init();
					$('#company-contacts').show();
					$('#clients-loading').hide();
				}
			});
		}
	};
	$(function()
	{
		$.invoice_recurring.init();
	});
})(jQuery);