(function($)
{    
	// Ok lets go...
    $.invoice = {
		send: function($title) {
			
			var $modalTemplate = $('<div>'+$('#invoice-send-jstemplate').html()+'</div>');
			var title = ($title) ? ($title === 'reminder') ? "Send Reminder" : "Send Thank You" : "Send Invoice";
			$.modal.show({
				title: title,
				content: $modalTemplate.html(),
				formAction: location.href,
				actionText: "Send Email",
				actionTextSubmitting: "Sending Email <i class='fa fa-spinner fa-spin'></i>",
				openCallback: function() {
		            $.formInputs.init();
					$.formSelect2.init();
				}
			});
		},
		add_payment: function() {
			
			var $modalTemplate = $('<div>'+$('#invoice-payment-jstemplate').html()+'</div>');
			
			$.modal.show({
				title: "Record Invoice Payment",
				content: $modalTemplate.html(),
				formAction: location.href,
				actionText: "Save Payment",
				actionTextSubmitting: "Saving Payment <i class='fa fa-spinner fa-spin'></i>",
				openCallback: function() {
		            $.formInputs.init();
					$.formSelect2.init();
				}
			});

		},
		write_off : function() {
			var $modalTemplate = $('<div>'+$('#invoice-writeoff-jstemplate').html()+'</div>');
			$.modal.show({
				title: "Write Off Invoice",
				content: $modalTemplate.html(),
				formAction: location.href,
				actionText: "Write Off Invoice",
				actionTextSubmitting: "Saving Invoice <i class='fa fa-spinner fa-spin'></i>",
				openCallback: function() {
		            $.formInputs.init();
					$.formSelect2.init();
				}
			});
		},
		trash : function() {
			return $.dialog.confirm({
				title: "Trash Invoice",
				description: "Are your sure you want to trash this invoice?",
				actionText: "Trash",
				callback: function() {
					$('#trashInvoiceForm').removeClass('submitting').submit();
				}
			});
		},
		remove_payment : function($form,$message) {
			return $.dialog.confirm({
				title: "Remove Payment",
				description: $message,
				actionText: "Remove Payment",
				callback: function() {
					$form.removeClass('submitting').submit();
				}
			});
		}
	};
})(jQuery);